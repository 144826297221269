import React, { Suspense, useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { CypressHistorySupport } from "cypress-react-router";
import { Dimmer, Image, Loader, Sidebar } from "semantic-ui-react";

import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";

import SuccessToast from "./components/SuccessToast";
import ErrorToast from "./components/ErrorToast";
import { CoreErrorToast, CoreSuccessToast } from "innercast-core-js/dist/module";
import { AuthGroup, WindowMinWidthForSidebar } from "Constants";
import { recordAnalytics } from "lib/analytics";
import { getViewerFELocation } from "lib/Utils";
import AccessDenied from "assets/access_denied.svg";

import SidebarContent from "views/Sidebar/SidebarContent";
import TopBar from "components/TopBar";
import LazyLoadingComponentPlaceholder from "components/LazyLoadingComponentPlaceholder";
import MyErrorBoundary from "components/MyErrorBoundary";
import TealiumViewListener from "components/TealiumViewListener";

import "styles/App.css";
import "styles/ArticlesScreenCss.css";
import "styles/Components.css";
import "styles/CreateAndDraftsScreenCss.css";
import "styles/CRM.css";
import "styles/Public.css";
import "styles/react-contextmenu.css";
import "styles/ResetPasswordCodeSearch.css";
import "styles/ResetPasswordCodeTable.css";

const LoginScreen = React.lazy(() => import("Authentication/screens/AuthLoginScreen"));
const CreateAccountScreen = React.lazy(() => import("Authentication/screens/AuthCreateAccountScreen"));
const TwoFactorScreen = React.lazy(() => import("Authentication/screens/TwoFactorAuthenticationScreen"));
const AuthForgotPasswordScreen = React.lazy(() => import("Authentication/screens/AuthForgotPasswordScreen"));

const Articles = React.lazy(() => import("containers/ArticlesScreen"));
// const AllDraftsScreen = React.lazy(() => import("AllDrafts/screens/AllDrafts"));
const CRMLanding = React.lazy(() => import("containers/CRMLandingScreen"));
const CRMManageScreen = React.lazy(() => import("containers/CRMManageScreen"));

const CronJobManagerScreen = React.lazy(() => import("CronJobManager/containers/CronJobManagerScreen"));

const SocialMediaGuidelines = React.lazy(() => import("containers/SocialMediaGuidelinesScreen"));
const PostingGuidelines = React.lazy(() => import("containers/PostingGuidelinesScreen"));
const TermsAndConditions = React.lazy(() => import("containers/TermsAndConditionsScreen"));

const HiddenAnalytics = React.lazy(() => import("containers/Reports/HiddenAnalyticsScreen"));
const Analytics = React.lazy(() => import("containers/Reports/AnalyticsScreen"));
const Reports = React.lazy(() => import("containers/Reports/ReportsScreen"));
const ReportsChirper = React.lazy(() => import("containers/Reports/ReportsChirperScreen"));
const ReportsBatch = React.lazy(() => import("containers/Reports/ReportsBatchScreen"));
const HashtagAnalytics = React.lazy(() => import("HashtagAnalytics/HashtagAnalyticsScreen"));
const AuthenticationFunnelWrapper = React.lazy(() => import("containers/Reports/AuthenticationFunnelScreen"));
const ContentCompetitionWrapper = React.lazy(() => import("containers/Reports/ContentCompetitionScreen"));
const ContentReportWrapper = React.lazy(() => import("containers/Reports/ContentReportScreen"));

const TweeterHome = React.lazy(() => import(/* webpackChunkName: "tweeter" */ "containers/TweeterHomeParent"));
// const ChatHome = React.lazy(() => import("containers/ChatHomeParent"));
// const EventsList = React.lazy(() => import("containers/EventsListWrapper"));
// const EventEditor = React.lazy(() => import("containers/EventEditorWrapper"));
// const EventMicrositeScreen = React.lazy(() => import("containers/EventMicrosite/EventMicrositeScreenParent"));
// const EventMicrositeLoginScreen = React.lazy(() => import("containers/EventMicrosite/EventMicrositeLoginScreenParent"));
// const EventMicrositeCreateAccountScreen = React.lazy(() =>
//     import("containers/EventMicrosite/EventMicrositeCreateAccountScreenParent"),
// );
// const EventMicrositeForgotPasswordScreen = React.lazy(() =>
//     import("containers/EventMicrosite/EventMicrositeForgotPasswordScreenParent"),
// );

const EmailTemplateEditor = React.lazy(() => import("containers/EmailTemplates/EmailTemplateEditorScreen"));
const EmailTemplatesList = React.lazy(() => import("containers/EmailTemplates/EmailTemplatesListScreen"));

// const UntaggedContent = React.lazy(() => import("UntaggedContent/UntaggedContentScreen"));

const CropProductInfo = React.lazy(() => import("CropProductInfo/screens/CropProductInfoScreen/CropProductInfoScreen"));
const ProductDetails = React.lazy(() => import("CropProductInfo/screens/ProductDetailsScreen/ProductDetailsScreen"));
const CropDetails = React.lazy(() => import("CropProductInfo/screens/CropDetailsScreen/CropDetailsScreen"));
const DistrictDetails = React.lazy(() => import("CropProductInfo/screens/DistrictDetailsScreen/DistrictDetailsScreen"));
const CropOverrideScreen = React.lazy(() => import("CropProductInfo/screens/CropOverrideScreen/CropOverrideScreen"));
const ProductOverrideScreen = React.lazy(() => import("CropProductInfo/screens/ProductOverrideScreen/ProductOverrideScreen"));
// const QRG = React.lazy(() => import("QRG/screens/QRGScreen"));
// const TopicDetails = React.lazy(() => import("QRG/screens/TopicDetailsScreen"));
// const DistrictQRG = React.lazy(() => import("QRG/screens/DistrictQRGScreen"));
// const ComposeQRG = React.lazy(() => import("QRG/screens/ComposeQRGScreen"));

const QuillEditorScreenParent = React.lazy(() => import("containers/Editor/QuillEditorScreenParent"));
const EditorVideoScreenParent = React.lazy(() => import("containers/Editor/EditorVideoScreenParent"));
const EditorListArticleParent = React.lazy(() => import("containers/Editor/EDListScreenParent"));
const EditorListVideoParent = React.lazy(() => import("components/Compose/Video/VideoListScreen"));
const VideoCompose = React.lazy(() => import("components/Compose/Video/VideoCompose"));
const ObservationCompose = React.lazy(() => import("components/Compose/Observation/ObservationEditor"));
const ArticleVersionHistory = React.lazy(() => import("containers/Editor/ArticleVersionHistoryScreenParent"));
const MyContent = React.lazy(() => import("ContentManager/screens/MyContent"));
const ForReview = React.lazy(() => import("ContentManager/screens/ForReview"));

const AzureMediaScreen = React.lazy(() => import("containers/MediaScreenParent"));

const DeveloperDashboard = React.lazy(() => import("Developer/screens/DashboardScreen"));
const DeveloperDocumentation = React.lazy(() => import("Developer/screens/DocumentationScreen"));
const DeveloperAccount = React.lazy(() => import("Developer/screens/AccountSettingsScreen"));

const ClaimLiteUser = React.lazy(() => import("ClaimLiteUser/screens/ClaimLiteUserScreen"));

const BigDataWrapper = React.lazy(() => import("containers/BigDataParent"));

const Alite27Wrapper = React.lazy(() => import("containers/Alite27Wrapper"));

// const StoryList = React.lazy(() => import("StoryCompose/screens/StoryListScreen"));
// const EditStory = React.lazy(() => import("StoryCompose/screens/EditStoryScreen"));
// const EditSlide = React.lazy(() => import("StoryCompose/screens/EditSlideScreen"));

const ContentManager = React.lazy(() => import("ContentManager/screens/ContentManager"));
const ProjectDetails = React.lazy(() => import("ContentManager/screens/ProjectDetails"));
const ProjectDetailsReadOnly = React.lazy(() => import("ContentManager/screens/ProjectDetailsReadOnly"));
const DraftedContentScreen = React.lazy(() => import("DraftedContent/screens/DraftedContent"));

const mql = window.matchMedia(`(min-width: 800px)`);

function CustomRender({ children, divParent = false }) {
    let content = children;

    if (divParent) {
        content = (
            <div
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "100%",
                }}>
                {children}
            </div>
        );
    }

    return (
        <MyErrorBoundary>
            <Suspense fallback={<LazyLoadingComponentPlaceholder />}>{content}</Suspense>
        </MyErrorBoundary>
    );
}

function App(props) {
    const width = window.innerWidth;
    const [tourCount, setTourCount] = useState(0);
    const [isTourOpen, setTourOpen] = useState(false);
    const [sidebarDocked, setSidebarDocked] = useState(width > WindowMinWidthForSidebar);
    const [sidebarOpen, setSidebarOpen] = useState(width > WindowMinWidthForSidebar && props.widgetstate.sidebarVisible);
    const [verifyingUser, setVerifyingUser] = useState(true);

    const logInStatusWatcher = useRef();

    useEffect(
        () => {
            props.validate();
            _verifyUserInfo();
            props.getBadgesForContent();

            if (!props.widgetstate.mainTourHasBeenWatched) {
                // main tour has not been viewed... show it.
                _showMainTour();
            }

            logInStatusWatcher.current = setInterval(() => {
                _verifyUserInfo(false);
            }, 1000 * 60 * 5);

            try {
                mql.addEventListener("change", mediaQueryChanged);
            } catch (e) {
                mql.addListener(mediaQueryChanged);
            }

            return () => {
                try {
                    mql.removeEventListener("change", mediaQueryChanged);
                } catch (e) {
                    mql.removeListener(mediaQueryChanged);
                }

                if (logInStatusWatcher.current) {
                    clearInterval(logInStatusWatcher.current);
                }
            };
        },
        // eslint-disable-next-line
        [],
    );

    const _verifyUserInfo = (refresh = true) => {
        if (refresh) {
            setVerifyingUser(true);
        }
        props.verifyUserInfo(() => {
            if (refresh) {
                setVerifyingUser(false);
            }
        });
    };

    const _showMainTour = () => {
        if (!sidebarOpen || !sidebarDocked) {
            setTimeout(() => {
                setTourCount(tourCount + 1);
                setTourOpen(true);
            }, 250);
        } else {
            setSidebarOpen(true);
            setTourCount(tourCount + 1);
            setTourOpen(true);
        }

        props.setMainTourAsWatched();
    };

    const _showChangeLog = () => props.toggleChangelogVisibility();

    const onSetSidebarOpen = (open) => {
        if (!isTourOpen) {
            setSidebarOpen(open);
        }
    };

    const mediaQueryChanged = () => {
        if (props.widgetstate.sidebarVisible) {
            setSidebarDocked(mql.matches);
            setSidebarOpen(mql.matches);
        }
    };

    const _handleNavClick = () => {
        if (!isTourOpen) {
            setSidebarOpen(sidebarDocked);
        }
        _verifyUserInfo();
    };

    const _toggleSidebarDocked = () => {
        const newVal = !props.widgetstate.sidebarVisible;

        props.toggleSidebarVisible(newVal);
        setSidebarDocked(newVal);
        setSidebarOpen(newVal);
    };

    if (verifyingUser) {
        return <Dimmer active children={<Loader />} />;
    }

    const renderRoute = ({ Component = Articles, routeProps = {}, isWebview = false, divParent = false }) => {
        const allProps = Object.assign(routeProps, props);
        if (isWebview) {
            allProps.isWebview = isWebview;
        }
        return <CustomRender children={<Component {...allProps} />} divParent={divParent} />;
    };

    const authenticated = props.user.isLoggedIn && props.user.uid;

    const renderTermsAndConditions = (routeProps) => renderRoute({ Component: TermsAndConditions, routeProps });

    const renderCRMManage = (routeProps) => renderRoute({ Component: CRMManageScreen, routeProps });

    const renderEditorListArticleParent = (routeProps, isWebview = false) =>
        renderRoute({ Component: EditorListArticleParent, routeProps, isWebview, divParent: true });

    const renderEditorListVideoParent = (routeProps, isWebview = false) =>
        renderRoute({ Component: EditorListVideoParent, routeProps, isWebview, divParent: true });

    const renderVideoCompose = (routeProps, isWebview = false) => renderRoute({ Component: VideoCompose, routeProps, isWebview });

    const renderObservationCompose = (routeProps, isWebview = false) => renderRoute({ Component: ObservationCompose, routeProps, isWebview });

    const renderQuillEditor = (routeProps, isWebview = false) =>
        renderRoute({ Component: QuillEditorScreenParent, routeProps, isWebview });

    const renderVersionHistoryScreen = (routeProps, isWebview = false) =>
        renderRoute({ Component: ArticleVersionHistory, routeProps, isWebview, divParent: true });

    const renderContentScreen = (routeProps, isWebview = false) =>
        renderRoute({ Component: MyContent, routeProps, isWebview, divParent: true });

    const renderForReviewScreen = (routeProps, isWebview = false) =>
        renderRoute({ Component: ForReview, routeProps, isWebview, divParent: true });

    const renderEditorVideoScreen = (routeProps) =>
        renderRoute({ Component: EditorVideoScreenParent, routeProps, divParent: true });

    // const renderEventMicrositeLoginScreen = (routeProps) => {
    //     if (authenticated) {
    //         return <Redirect to={`/event/${routeProps?.match?.params?.slug}`} />;
    //     }
    //     return renderRoute({ Component: EventMicrositeLoginScreen, routeProps, divParent: true });
    // };

    // const renderEventMicrositeScreen = (routeProps) => {
    //     if (!authenticated) {
    //         return <Redirect to={`/login/${routeProps?.match?.params?.slug}`} />;
    //     }
    //     return renderRoute({ Component: EventMicrositeScreen, routeProps, divParent: true });
    // };

    // const renderEventMicrositeCreateAccountScreen = (routeProps) => {
    //     if (authenticated) {
    //         return <Redirect to={`/event/${routeProps?.match?.params?.slug}`} />;
    //     }
    //     return renderRoute({ Component: EventMicrositeCreateAccountScreen, routeProps, divParent: true });
    // };

    // const renderEventMicrositeForgotPasswordScreen = (routeProps) => {
    //     if (authenticated) {
    //         return <Redirect to={`/event/${routeProps?.match?.params?.slug}`} />;
    //     }
    //     return renderRoute({ Component: EventMicrositeForgotPasswordScreen, routeProps, divParent: true });
    // };

    const renderPublic = () => {
        const renderLogin = (routeProps) => renderRoute({ Component: LoginScreen, routeProps });
        const renderCreateAccount = (routeProps) => renderRoute({ Component: CreateAccountScreen, routeProps });
        const renderResetPassword = (routeProps) => renderRoute({ Component: AuthForgotPasswordScreen, routeProps });
        const renderTwoFactor = (routeProps) => renderRoute({ Component: TwoFactorScreen, routeProps });

        return (
            <div>
                <div
                    style={{
                        background: "white",
                        color: "black",
                        height: "99vh",
                    }}>
                    <div
                        style={{
                            position: "relative",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            height: "calc(100%)",
                        }}>
                        <Switch>
                            <Route exact path="/2fa" render={renderTwoFactor} />
                            <Route exact path="/resetpassword" render={renderResetPassword} />
                            <Route
                                exact
                                path="/developer/:inviteCode"
                                render={(routeProps) =>
                                    renderCreateAccount(
                                        Object.assign({ isDeveloperCreation: true }, { ...props }, { ...routeProps }),
                                    )
                                }
                            />
                            <Route exact path="/createaccount" render={renderCreateAccount} />
                            <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                            <Route exact path="/logout" render={() => <Redirect to="/" />} />
                            <Route path="/" render={renderLogin} />
                            <Route render={renderLogin} />
                        </Switch>
                    </div>
                </div>
                <TealiumViewListener {...props} />
                <div style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "#009e00", height: "5px" }} />
            </div>
        );
    };

    const renderAuthenticated = () => {
        let animation = sidebarDocked ? "slide along" : "overlay";
        let contentLeftMargin = sidebarOpen && sidebarDocked ? 260 : 0;

        if (props.user && props.user.groupID) {
            const auth_group = props.user.groupID;

            if (auth_group === AuthGroup.Viewer || auth_group === AuthGroup.InternalViewer || auth_group === AuthGroup.Lite) {
                let tealium_dict = {
                    view_name: "login",
                    page_category: "Login",
                    event_type: "click",
                    event_name: `attempt_internal_login_with_${props.user.groupName.toLowerCase()}_account`,
                    tealium_event: `attempt_internal_login_with_${props.user.groupName.toLowerCase()}_account`,
                };
                recordAnalytics(tealium_dict, "Login", "Error", "Access Denied");

                const location = getViewerFELocation();

                return (
                    <div className="no-access-div">
                        <p className="access-denied-header">Access Denied</p>
                        <Image src={AccessDenied} className="access-denied-image" />
                        <p className="access-denied-description">
                            You are trying to access the Grow Smart™ Live author tools with a {props.user.groupName} account (
                            {props.user.email})
                        </p>
                        <div className="access-redirect-div">
                            <div className="access-redirect-button" onClick={() => window.location.assign(location)}>
                                <p className="redirect-option-text">Go to viewer home page</p>
                            </div>
                            <div className="access-redirect-button" onClick={props.logout}>
                                <p className="redirect-option-text">Re-enter author credentials</p>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div>
                <TopBar
                    {...props}
                    showAccountMenu={true}
                    showMainTour={_showMainTour}
                    menuIsVisible={sidebarDocked}
                    sidebarOpen={sidebarOpen}
                    didAskForMenu={onSetSidebarOpen}
                />
                <Sidebar
                    data-tut="reactour__sidebar"
                    visible={sidebarOpen}
                    animation={animation}
                    className="sidebar"
                    onHide={() => {
                        if (!sidebarDocked) {
                            setTimeout(() => {
                                onSetSidebarOpen(false);
                            }, 100);
                        }
                    }}
                    style={{ width: 260 }}
                    width="thin">
                    <SidebarContent
                        onNavClick={_handleNavClick}
                        toggleSidebarDocked={_toggleSidebarDocked}
                        showChangeLog={_showChangeLog}
                        {...props}
                    />
                </Sidebar>

                <div
                    style={{
                        color: "black",
                        marginLeft: contentLeftMargin,
                        height: "100vh",
                    }}>
                    <main
                        style={{
                            position: "relative",
                            top: 66,
                            right: 0,
                            bottom: 0,
                            height: "calc(100% - 66px)",
                        }}>
                        {_routesForPermissionLevel(props.user.groupID)}
                    </main>
                </div>
                <TealiumViewListener {...props} />
            </div>
        );
    };

    const _routesForPermissionLevel = (auth_group) => {
        if (auth_group === undefined || auth_group === null) {
            return null;
        }

        const renderArticles = (routeProps) => renderRoute({ Component: Articles, routeProps });
        // const renderAllDrafts = (routeProps) => renderRoute({ Component: AllDraftsScreen, routeProps });
        const renderCRMLanding = (routeProps) => renderRoute({ Component: CRMLanding, routeProps });
        const renderClaimLiteUser = (routeProps) => renderRoute({ Component: ClaimLiteUser, routeProps });
        const renderReports = (routeProps) => renderRoute({ Component: Reports, routeProps });
        const renderReportsChirper = (routeProps) => renderRoute({ Component: ReportsChirper, routeProps });
        const renderReportsBatch = (routeProps) => renderRoute({ Component: ReportsBatch, routeProps });
        const renderHashtagAnalytics = (routeProps) => renderRoute({ Component: HashtagAnalytics, routeProps });
        const renderHiddenAnalytics = (routeProps) => renderRoute({ Component: HiddenAnalytics, routeProps });
        const renderAnalytics = (routeProps) => renderRoute({ Component: Analytics, routeProps });
        const renderBigDataScreen = (routeProps) => renderRoute({ Component: BigDataWrapper, routeProps });
        const renderAuthenticationFunnelScreen = (routeProps) => renderRoute({ Component: AuthenticationFunnelWrapper, routeProps });
        const renderContentCompetitionScreen = (routeProps) => renderRoute({ Component: ContentCompetitionWrapper, routeProps });
        const renderContentReportScreen = (routeProps) => renderRoute({ Component: ContentReportWrapper, routeProps });
        const renderCronJobManager = (routeProps) => renderRoute({ Component: CronJobManagerScreen, routeProps });
        const renderSocialMediaGuidelines = (routeProps) => renderRoute({ Component: SocialMediaGuidelines, routeProps });
        const renderPostingGuidelines = (routeProps) => renderRoute({ Component: PostingGuidelines, routeProps });
        const renderTweeterHome = (routeProps) => renderRoute({ Component: TweeterHome, routeProps, divParent: true });
        // const renderChatHome = (routeProps) => renderRoute({ Component: ChatHome, routeProps, divParent: true });
        // const renderEventsList = (routeProps) => renderRoute({ Component: EventsList, routeProps, divParent: true });
        // const renderEventEditor = (routeProps) => renderRoute({ Component: EventEditor, routeProps, divParent: true });
        const renderEmailTemplateEditor = (routeProps) =>
            renderRoute({ Component: EmailTemplateEditor, routeProps, divParent: true });
        const renderEmailTemplatesList = (routeProps) =>
            renderRoute({ Component: EmailTemplatesList, routeProps, divParent: true });
        // const renderUntaggedContent = (routeProps) => renderRoute({ Component: UntaggedContent, routeProps });
        const renderCropProductInfo = (routeProps) => renderRoute({ Component: CropProductInfo, routeProps });
        const renderProductDetails = (routeProps) => renderRoute({ Component: ProductDetails, routeProps });
        const renderCropDetails = (routeProps) => renderRoute({ Component: CropDetails, routeProps });
        const renderDistrictDetails = (routeProps) => renderRoute({ Component: DistrictDetails, routeProps });
        const renderCropOverrideScreen = (routeProps) => renderRoute({ Component: CropOverrideScreen, routeProps });
        const renderProductOverrideScreen = (routeProps) => renderRoute({ Component: ProductOverrideScreen, routeProps });
        // const renderQRG = (routeProps) => renderRoute({ Component: QRG, routeProps });
        // const renderTopicDetails = (routeProps) => renderRoute({ Component: TopicDetails, routeProps });
        // const renderDistrictQRG = (routeProps) => renderRoute({ Component: DistrictQRG, routeProps });
        // const renderComposeQRG = (routeProps) => renderRoute({ Component: ComposeQRG, routeProps });
        const renderAzureMedia = (routeProps) => renderRoute({ Component: AzureMediaScreen, routeProps, divParent: true });
        // const renderStoryList = (routeProps) => renderRoute({ Component: StoryList, routeProps });
        // const renderEditStory = (routeProps) => renderRoute({ Component: EditStory, routeProps });
        // const renderEditSlide = (routeProps) => renderRoute({ Component: EditSlide, routeProps });
        const renderAlite27Screen = (routeProps) => renderRoute({ Component: Alite27Wrapper, routeProps, divParent: true });
        const renderContentManager = (routeProps) => renderRoute({ Component: ContentManager, routeProps });
        const renderProjectDetails = (routeProps) => renderRoute({ Component: ProjectDetails, routeProps });
        const renderProjectDetailsReadOnly = (routeProps) => renderRoute({ Component: ProjectDetailsReadOnly, routeProps });
        const renderDraftedContent = (routeProps) => renderRoute({ Component: DraftedContentScreen, routeProps });

        if (auth_group === AuthGroup.Viewer || auth_group === AuthGroup.InternalViewer || auth_group === AuthGroup.Lite) {
            return null;
        } else if (auth_group === AuthGroup.AccountManager) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/hidden/reports/articles" render={renderReports} />
                    <Route exact path="/hidden/analytics" render={renderHiddenAnalytics} />
                    <Route exact path="/analytics" render={renderAnalytics} />
                    <Route exact path="/hidden/analytics/bigdata" render={renderBigDataScreen} />
                    <Route exact path="/hidden/analytics/bigdata/:category" render={renderBigDataScreen} />
                    <Route exact path="/hidden/analytics/bigdata/:category/:action" render={renderBigDataScreen} />
                    <Route exact path="/hidden/analytics/bigdata/:category/:action/:label" render={renderBigDataScreen} />
                    <Route exact path="/hidden/reports/chirps" render={renderReportsChirper} />
                    <Route exact path="/hidden/reports/batch" render={renderReportsBatch} />
                    <Route exact path="/hidden/reports/hashtag" render={renderHashtagAnalytics} />
                    <Route exact path="/hidden/reports/authenticationfunnel" render={renderAuthenticationFunnelScreen} />
                    <Route exact path="/reports/contentcompetition" render={renderContentCompetitionScreen} />
                    <Route exact path="/reports/contentreport" render={renderContentReportScreen} />
                    <Route exact path="/CronJobManager" render={renderCronJobManager} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    {/* <Route exact path="/events" render={renderEventsList} /> */}
                    {/* <Route exact path="/events/edit/:id" render={renderEventEditor} /> */}
                    <Route exact path="/EmailTemplates/Create" render={renderEmailTemplateEditor} />
                    <Route exact path="/EmailTemplates" render={renderEmailTemplatesList} />
                    <Route exact path="/EmailTemplates/View" render={renderEmailTemplatesList} />
                    <Route exact path="/EmailTemplates/Edit/:id" render={renderEmailTemplateEditor} />
                    {/* <Route exact path="/untagged_content" render={renderUntaggedContent} /> */}
                    <Route exact path="/crop_product_info" render={renderCropProductInfo} />
                    <Route exact path="/crop_product_info/product/:id" render={renderProductDetails} />
                    <Route exact path="/crop_product_info/crop/:id" render={renderCropDetails} />
                    <Route exact path="/crop_product_info/district/:id" render={renderDistrictDetails} />
                    <Route exact path="/crop_product_info/district/:district_id/crop/:crop_id" render={renderCropOverrideScreen} />
                    <Route exact path="/crop_product_info/district/:district_id/product/:product_id" render={renderProductOverrideScreen} />
                    {/* <Route exact path="/all_drafts" render={renderAllDrafts} /> */}
                    <Route exact path="/editor" render={renderEditorListArticleParent} />
                    <Route exact path="/editor/video" render={renderEditorListVideoParent} />
                    <Route exact path="/editor/video/:id" render={renderVideoCompose} />
                    <Route exact path="/editor/observation/:id" render={renderObservationCompose} />
                    <Route exact path="/editor/:id" render={renderQuillEditor} />
                    <Route exact path="/editor/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/content" render={renderContentScreen} />
                    <Route exact path="/for_review" render={renderForReviewScreen} />
                    {/* <Route exact path="/StoryCompose" render={renderStoryList} /> */}
                    {/* <Route exact path="/StoryCompose/:story_id" render={renderEditStory} /> */}
                    {/* <Route exact path="/StoryCompose/:story_id/slide/:slide_id" render={renderEditSlide} /> */}
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    {/* <Route exact path="/qrg" render={renderQRG} /> */}
                    {/* <Route exact path="/qrg/district/:districtID" render={renderDistrictQRG} /> */}
                    {/* <Route exact path="/qrg/topic/:topicID" render={renderTopicDetails} /> */}
                    {/* <Route exact path="/qrg/topic/:topicID/guide/:guideID" render={renderComposeQRG} /> */}
                    <Route exact path="/content_manager" render={renderContentManager} />
                    <Route exact path="/content_manager/:id" render={renderProjectDetails} />
                    <Route exact path="/project_details/:id" render={renderProjectDetailsReadOnly} />
                    <Route exact path="/drafted_content" render={renderDraftedContent} />
                    <Route exact path="/all_drafts" render={() => <Redirect to={"/drafted_content"} />} />
                    <Route exact path="/unclaimed_projects" render={() => <Redirect to={"/drafted_content"} />} />
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.ProjectManager) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    {/* <Route exact path="/events" render={renderEventsList} /> */}
                    {/* <Route exact path="/events/edit/:id" render={renderEventEditor} /> */}
                    <Route exact path="/editor" render={renderEditorListArticleParent} />
                    <Route exact path="/editor/video" render={renderEditorListVideoParent} />
                    <Route exact path="/editor/video/:id" render={renderVideoCompose} />
                    <Route exact path="/editor/:id" render={renderQuillEditor} />
                    <Route exact path="/editor/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/content" render={renderContentScreen} />
                    <Route exact path="/for_review" render={renderForReviewScreen} />
                    <Route exact path="/project_details/:id" render={renderProjectDetailsReadOnly} />
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    {/* <Route exact path="/qrg" render={renderQRG} /> */}
                    {/* <Route exact path="/qrg/district/:districtID" render={renderDistrictQRG} /> */}
                    {/* <Route exact path="/qrg/topic/:topicID" render={renderTopicDetails} /> */}
                    {/* <Route exact path="/qrg/topic/:topicID/guide/:guideID" render={renderComposeQRG} /> */}
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.Reviewer) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    {/* <Route exact path="/events" render={renderEventsList} /> */}
                    {/* <Route exact path="/events/edit/:id" render={renderEventEditor} /> */}
                    <Route exact path="/editor" render={renderEditorListArticleParent} />
                    <Route exact path="/editor/video" render={renderEditorListVideoParent} />
                    <Route exact path="/editor/video/:id" render={renderVideoCompose} />
                    <Route exact path="/editor/observation/:id" render={renderObservationCompose} />
                    <Route exact path="/editor/:id" render={renderQuillEditor} />
                    <Route exact path="/editor/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/content" render={renderContentScreen} />
                    <Route exact path="/for_review" render={renderForReviewScreen} />
                    <Route exact path="/project_details/:id" render={renderProjectDetailsReadOnly} />
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.Author) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    {/* <Route exact path="/events" render={renderEventsList} /> */}
                    {/* <Route exact path="/events/edit/:id" render={renderEventEditor} /> */}
                    <Route exact path="/editor" render={renderEditorListArticleParent} />
                    <Route exact path="/editor/video" render={renderEditorListVideoParent} />
                    <Route exact path="/editor/video/:id" render={renderVideoCompose} />
                    <Route exact path="/editor/observation/:id" render={renderObservationCompose} />
                    <Route exact path="/editor/:id" render={renderQuillEditor} />
                    <Route exact path="/editor/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/content" render={renderContentScreen} />
                    <Route exact path="/project_details/:id" render={renderProjectDetailsReadOnly} />
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.PracticeAuthor) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    {/* <Route exact path="/events" render={renderEventsList} /> */}
                    {/* <Route exact path="/events/edit/:id" render={renderEventEditor} /> */}
                    <Route exact path="/editor" render={renderEditorListArticleParent} />
                    <Route exact path="/editor/video" render={renderEditorListVideoParent} />
                    <Route exact path="/editor/video/:id" render={renderVideoCompose} />
                    <Route exact path="/editor/observation/:id" render={renderObservationCompose} />
                    <Route exact path="/editor/:id" render={renderQuillEditor} />
                    <Route exact path="/editor/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/content" render={renderContentScreen} />
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.Advocate) {
            return (
                <Switch>
                    <Route exact path="/articles/:id" render={renderArticles} />
                    <Route exact path="/media" render={renderAzureMedia} />
                    <Route exact path="/crm" render={renderCRMLanding} />
                    <Route exact path="/crm/users" render={renderCRMManage} />
                    <Route exact path="/crm/claim_lite_user" render={renderClaimLiteUser} />
                    <Route exact path="/social_media_guidelines" render={renderSocialMediaGuidelines} />
                    <Route exact path="/posting_guidelines" render={renderPostingGuidelines} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/observations" render={renderTweeterHome} />
                    {/* <Route exact path="/chat" render={renderChatHome} /> */}
                    <Route exact path="/alite27" render={renderAlite27Screen} />
                    <Route path="/alite27" render={() => <Redirect to={"/alite27"} />} />
                    <Route exact path="/" render={renderArticles} />
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (auth_group === AuthGroup.Developer) {
            const renderDeveloperAccount = (routeProps) =>
                renderRoute({ Component: DeveloperAccount, routeProps, divParent: true });
            const renderDeveloperDocumentation = (routeProps) =>
                renderRoute({ Component: DeveloperDocumentation, routeProps, divParent: true });
            const renderDeveloperDashboard = (routeProps) =>
                renderRoute({ Component: DeveloperDashboard, routeProps, divParent: true });

            return (
                <Switch>
                    <Route exact path="/developer/documentation" render={renderDeveloperDocumentation} />
                    <Route exact path="/developer" render={renderDeveloperDashboard} />
                    <Route exact path="/developer/team" render={renderDeveloperAccount} />
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/my_account/:asker/:target/:token" render={renderCRMManage} />
                    <Route exact path="/" render={() => <Redirect to="/developer" />} />
                    <Route render={renderDeveloperDashboard} />
                </Switch>
            );
        }
        return null;
    };

    return (
        <div className="root">
            <Router basename="/BASF/">
                <CypressHistorySupport />
                <Switch>
                    <Route exact path="/terms" render={renderTermsAndConditions} />
                    <Route
                        exact
                        path="/mobile-editor/:token"
                        render={(routeProps) => renderEditorListArticleParent(routeProps, true)}
                    />
                    <Route
                        exact
                        path="/mobile-editor/video/:token"
                        render={(routeProps) => renderEditorListVideoParent(routeProps, true)}
                    />
                    <Route
                        exact
                        path="/mobile-editor/video/:token/:id"
                        render={(routeProps) => renderVideoCompose(routeProps, true)}
                    />
                    <Route exact path="/mobile-editor/:token/:id" render={(routeProps) => renderQuillEditor(routeProps, true)} />
                    <Route exact path="/mobile-editor/:token/:id/version_history" render={renderVersionHistoryScreen} />
                    <Route exact path="/crm/mobile-user-details/:asker/:target/:token" render={renderCRMManage} />
                    <Route exact path="/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    {/* <Route exact path="/login/:slug" render={renderEventMicrositeLoginScreen} /> */}
                    {/* <Route exact path="/event/:slug" render={renderEventMicrositeScreen} /> */}
                    {/* <Route exact path="/createaccount/:slug" render={renderEventMicrositeCreateAccountScreen} /> */}
                    {/* <Route exact path="/forgot-password/:slug" render={renderEventMicrositeForgotPasswordScreen} /> */}
                    <Route render={authenticated ? renderAuthenticated : renderPublic} />
                </Switch>
            </Router>

            <SuccessToast setSuccess={props.setSuccess} message={props.messages.success_message} />
            <ErrorToast message={props.messages.error_message} />
            <CoreSuccessToast />
            <CoreErrorToast />
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        widgetstate: state.widgetstate,
        user: state.user,
        messages: state.messages,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
